import * as React from 'react';
import Paper from '@mui/material/Paper';
import "../styles/components/Charts.css";
import {
  Chart,
  Title,
  ArgumentAxis,
  BarSeries,
  ValueAxis,
} from '@devexpress/dx-react-chart-material-ui';

export default function ChartEvento ({ eventos }){

  return (
    <>
    {
      eventos ?
        <Paper className='charts'>
          <Chart className='charts__eventos'
            data={eventos}
            rotated
          >
            <BarSeries
              valueField="evento_cantidad"
              argumentField="evento_nombre"
              barWidth={0.5}
            />
            <ArgumentAxis />
            <ValueAxis />
            <Title text="Eventos" />
          </Chart>
        </Paper>
        :
        <h3>No hay eventos registrados en este rango de fechas, por favor elige otro rango</h3>
      }
    </>
  );

}