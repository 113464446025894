import React, { Fragment, useEffect, useState } from "react";
import { getData } from "../api/subscribers-api";
import ABMEvento from "../components/ABM_components/ABMEvento";
import AlignedBox from "../components/mui/AlignedBox";
import AlignedSpinner from "../components/mui/AlignedSpinner";
import { useMyContext } from "../context/context-provider";
import "../styles/routes/SubscribersList.css";


const ABMEventRender = () => {
  const initialState = { isLoading: true, error: null, data: null};
  const [state, setState] = useState(initialState);
  const {setOpenAlert, updated, setUpdated, setAbmAlert} = useMyContext();

  const handleUpdated = (abmType) => {
    setOpenAlert(true);
    setAbmAlert(abmType)
    updated ? setUpdated(false) : setUpdated(true);
  }
  useEffect(()=>{
    let isMounted = true;
    setState(initialState)
    const mainData = [
      getData(`200/evento/listado`, 'GET'),
      getData(`100/dominio/valor/ADMDOMINIOESTADO`, 'GET')
    ]
    Promise.all(mainData)
    .then((data)=> {
      if (isMounted){
        setState({ isLoading: false, error: null, data: data })
      }
    })
    .catch((error)=> {
      setState({ isLoading: false, error: "Sorry, nothing was returned", data: null })
    })
    return () => { isMounted = false };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updated])

  return (
    <Fragment>
      {state.isLoading && <AlignedSpinner/>}
      {state.error && <AlignedBox>{state.error}</AlignedBox>}
      {state.data && <ABMEvento datos={state.data} handleUpdated={handleUpdated} />}
    </Fragment>
    );
}

export default ABMEventRender;
