import { API_URL } from "./api-config";
const hashed = window.btoa('user_prisma:ns3r_pr!5m@_d3^');
const headerBASE    = new Headers();

headerBASE.append('Accept', 'application/json;charset=UTF-8');
headerBASE.append("Authorization", `Basic ${hashed}`);
headerBASE.append('Content-type', 'application/json;charset=UTF-8');


export function getData(paramsUrl, paramsMethod){
  const urlJSON = `${API_URL}/${paramsUrl}`;
  let requestJSON;
  if (paramsMethod === 'GET') {
      requestJSON = new Request(urlJSON, {
      method: paramsMethod,
      headers: headerBASE,
      redirect: 'follow',
      credentials: 'same-origin',
      mode: 'cors',
    })
  }
  const dataResult = fetch(requestJSON)
    .then((data) => data.json())
    .then((response) => { return response.data; })

    return dataResult;
};

export function postData(paramsUrl, paramsMethod, parmBODY){
  const urlJSON = `${API_URL}/${paramsUrl}`;
  let requestJSON;
  if (paramsMethod === 'POST') {
      requestJSON = new Request(urlJSON, {
      method: paramsMethod,
      headers: headerBASE,
      redirect: 'follow',
      credentials: 'same-origin',
      mode: 'cors',
      body: parmBODY,
    })
  }
  const dataResult = fetch(requestJSON)
    .then((data) => data.json())
    .then((response) => { return response; })

    return dataResult;
};
export function putData(paramsUrl, paramsMethod, parmBODY){
  const urlJSON = `${API_URL}/${paramsUrl}`;
  let requestJSON;
      requestJSON = new Request(urlJSON, {
      method: paramsMethod,
      headers: headerBASE,
      redirect: 'follow',
      credentials: 'same-origin',
      mode: 'cors',
      body: parmBODY,
    })
  const dataResult = fetch(requestJSON)
    .then((data) => data.json())
    .then((response) => { return response; })

    return dataResult;
};
