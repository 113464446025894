// import { Button } from "@mui/material";
import CustomLoadingButton from './Buttons/CustomLoadingButton';
import React, { useEffect, useState } from "react";
// import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { postData } from "../api/subscribers-api";
import "../styles/routes/Login.css";
import { Box } from '@mui/material';

const Login = () => {
  const navigate = useNavigate();
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    localStorage.clear();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    let usuario_nombre;
    let usuario_contrasenha;
    Object.entries(e.target).forEach(([eventKey, eventValue]) => {
      if (eventValue.name === "01") {
        usuario_nombre = eventValue.value;
      } else if (eventValue.name === "02") {
        usuario_contrasenha = eventValue.value;
      }
    });

    let body = JSON.stringify({
      usuario_nombre: usuario_nombre,
      usuario_contrasenha: usuario_contrasenha,
      auditoria_usuario_codigo: 6,
      auditoria_ip: "1",
    });
    try {
      let userData = await postData("100/usuario/login", "POST", body);
      if (userData.code === 200) {
        localStorage.setItem("userData", userData.data[0].usuario_codigo);
        localStorage.setItem("user_rol", JSON.stringify(userData.data[0].tipo_rol_parametro));
        localStorage.setItem("user_data", JSON.stringify(userData.data[0]));
        localStorage.setItem("person_code", JSON.stringify(userData.data[0].persona_codigo));
        const user =  JSON.parse(localStorage.getItem('user_rol'));
        setMessage("Enviado correctamente!");
        user === 2 ? navigate("/home/admin") : navigate("/home/admin/inscriptos")
      } else {
        setLoading(false)
        setError("El usuario o la contraseña es incorrecto");
      }
    } catch (err) {
      setError(err);
    }
  };

  return (
    <div className="login_container">
      <Box
          component="img"
          sx={{
          maxHeight: { xs: 100, md: 100 },
          maxWidth: { xs: 180, md: 180 },
          margin: '10px'
          }}
          alt="Umax_Logo"
          src="https://pictshare.net/3fnprw.png"
      />
      <form
        className="login_form-container"
        onSubmit={handleSubmit}
        action="POST"
      >
        <span className="login_span">Correo electrónico</span>
        <input type="email" required className="login_input" name="01" />
        <span className="login_span">Contraseña</span>
        <input type="password" required className="login_input" name="02" />
        <span>
          {message ? (
            <p className="message">{message}</p>
          ) : (
            <p className="error-message_login">{error}</p>
          )}
        </span>
        <CustomLoadingButton
          loading={loading}
        >
          Iniciar Sesión
        </CustomLoadingButton>
      </form>
    </div>
  );
};
export default Login;
