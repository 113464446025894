import React, { useState } from "react";
import { postData } from "../api/subscribers-api";
import "../styles/routes/SubscribeForm.css";

const SubscribeForm = ({ data }) => {
  const person_code =  JSON.parse(localStorage.getItem('person_code'));
  const asesor_code = data[1].filter(item => item.tipo_estado_parametro !== 2 && item.persona_codigo === person_code).map(items => items.evento_asesor_codigo);
  const [carrera, setCarrera] = useState();
  const [evento, setEvento] = useState("");
  const [asesor, setAsesor] = useState(asesor_code[0]);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [comentario, setComentario] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    const usuario_codigo = localStorage.getItem("userData");
    let body = JSON.stringify({
      auditoria_ip: "0.0.0.0",
      auditoria_usuario_codigo: usuario_codigo,
      tipo_estado_parametro: 1,
      tipo_sexo_parametro: 1,
      tipo_carrera_parametro: carrera,
      evento_formulario_nombre: name,
      evento_codigo: evento,
      evento_asesor_codigo: asesor,
      evento_formulario_email: email,
      evento_formulario_telefono: mobileNumber,
      evento_formulario_observacion: comentario,
    });
    try {
      let response = await postData("200/eventoformulario", "POST", body);
      if (response.code === 201 || response.code === 200) {
        setError("");
        setMessage("Guardado exitosamente!");
        window.location.reload();
      } else {
        setError(response.message_short);
      }
    } catch (err) {
      setError(err);
    }
  };
  return (
    <div className="subcribe_container">
      <h2 className="subcribe_h2">Inscripción</h2>
      <form
        className="subcribe_form-container"
        onSubmit={handleSubmit}
        action="POST"
      >
        <select
          className="subcribe_form-select"
          defaultValue={"default"}
          required
          onBlur={(e) => setCarrera(e.target.value)}
        >
          <option disabled className="subcribe_select-option" value={"default"}>
            {" "}
            Seleccionar Carrera...{" "}
          </option>
          {data[2].filter(item => item.tipo_estado_parametro !== 2).map((items, index) => (
            <option
              key={index}
              value={items.tipo_parametro}
              className="subcribe_select-option"
            >
              {items.tipo_nombre_castellano}
            </option>
          ))}
        </select>
        <select
          className="subcribe_form-select"
          required
          defaultValue={asesor}
          onBlur={(e) => setAsesor(e.target.value)}
        >
          {data[1].filter(item => item.tipo_estado_parametro !== 2 && item.persona_codigo === person_code).map((items, index) => (
                <option
                  key={index}
                  value={items.evento_asesor_codigo}
                  className="subcribe_select-option"
                >{`${items.persona_nombre} ${items.persona_apellido}`}</option>
              )
            )
          }
        </select>
        <select
          className="subcribe_form-select"
          defaultValue={"default"}
          required
          onBlur={(e) => setEvento(e.target.value)}
        >
          <option disabled className="subcribe_select-option" value={"default"}>
            {" "}
            Seleccionar Evento...{" "}
          </option>
          {data[0].filter(item => item.tipo_estado_parametro !== 2).map((items, index) => (
            <option
              key={index}
              value={items.evento_codigo}
              className="subcribe_select-option"
            >
              {items.evento_nombre}
            </option>
          ))}
        </select>
        <input
          type="text"
          required
          onChange={(e) => setName(e.target.value)}
          className="subcribe_input"
          placeholder="Nombre y Apellido"
        />
        <input
          type="email"
          onChange={(e) => setEmail(e.target.value)}
          className="subcribe_input"
          placeholder="Correo Electronico"
        />
        <input
          type="text"
          required
          onChange={(e) => setMobileNumber(e.target.value)}
          className="subcribe_input"
          placeholder="Celular"
        />
        <textarea
          type="text"
          onChange={(e) => setComentario(e.target.value)}
          className="subcribe_input-text-area"
          placeholder="Comentario"
        />
        <div className="message">{message ? <p>{message}</p> : null}</div>
        <div className="error-message">{error ? <p>{error}</p> : null}</div>
        <input type="submit" value="Guardar" className="subcribe_button" />
      </form>
    </div>
  );
};
export default SubscribeForm;
