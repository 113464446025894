import React, { useState } from "react";
import DashboardDataRender from "../utils/DashboardDataRender";
import "../styles/components/Dashboard.css";
// import Chart2 from "./Chart2";
// import Charts from "./Charts";

const Dashboard = () => {
  const fecha = new Date();
  const currentYear = fecha.getFullYear();
  const from = "2020-01-01";
  const to = currentYear.toString() + "-12-31";
  const [desde, setDesde] = useState(from);
  const [hasta, setHasta] = useState(to);

  const handleSubmit = async (e) => {
    e.preventDefault();
  };

  return (
    <div className="dashboard">
      <nav className="dashboard__filters">
        <h2>Tablero</h2>
        <ul className="dashboard__filter-ul">
          <li className="dashboard__filter-li">Eventos</li>
          <li className="dashboard__filter-li">Asesores</li>
          <li className="dashboard__filter-li">Carreras</li>
        </ul>
      <form className="dashboard-form" onSubmit={handleSubmit} action='POST'>
        <label className="dashboard-label"> Filtrar desde</label>
        <input className="dashboard-input" type="date" required onBlur={(e) => setDesde(e.target.value)}/>
        <label className="dashboard-label" >hasta</label>
        <input className="dashboard-input" type="date" required onBlur={(e) => setHasta(e.target.value)}/>
        <input className="dashboard-submit" type="submit" value="Enviar"/>
      </form>
      </nav>
      <DashboardDataRender desde={desde} hasta={hasta}/>
    </div>
  );
}

export default Dashboard;