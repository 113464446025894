import React from "react";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import "../../styles/components/ABM.css";
import PopUpAdd from "../popups/PopUpAdd";
import AddCarrera from "../AddCarrera";
import PopUpEdit from "../popups/PopUpEdit";
import PopUpEye from "../popups/PopUpEYe";
import PopUpDelete from "../popups/PopUpDelete";

const ABMCarreras = ({ datos, handleUpdated }) => {
  let isAddOpened = true;

  const changeStyle = () => {
    isAddOpened = !isAddOpened;
    const addWrapper = document.getElementById("add-wrapper");
    if (isAddOpened) {
      addWrapper.style.display = "none";
    } else {
      addWrapper.style.display = "flex";
    }
  };
  return (
    <div className="abm__container">
      <nav className="abm__nav">
        <h2>ABM Carreras</h2>
        <div className="ABM__add-button">
          <PopUpAdd handleUpdated={handleUpdated}/>
        </div>
      </nav>
      <div className="ABM__add-wrapper" id="add-wrapper">
        <AddCarrera />
        <button className="ABM__close-button" onClick={changeStyle}>
          <AddCircleIcon className="close-button" />
        </button>
      </div>
      <table className="table">
        <thead className="table_head">
          <tr>
            <th>
              <p>Nombre</p>
            </th>
            <th>
              <p>Estado</p>
            </th>
            <th className="table_body-th-parametro">
              <p>Parámetro</p>
            </th>
            <th>
              <p>Código</p>
            </th>
            <th className="table_body-th-orden">
              <p>Orden</p>
            </th>
            <th>
              <p>Acción</p>
            </th>
          </tr>
        </thead>
        <tbody className="table_body">
        {datos[0].filter(item => item.tipo_estado_parametro !== 2).map((item, index) => {
            return (
                <tr className="table_body-tr" key={index}>
                  <td className="table_body-td">
                    <p>{item.tipo_nombre_castellano}</p>
                  </td>
                  <td className="table_body-td">
                    <p>{item.tipo_estado_nombre_castellano}</p>
                  </td>
                  <td className="table_body-td-parametro">
                    <p>{item.tipo_parametro}</p>
                  </td>
                  <td className="table_body-td">
                    <p>{item.tipo_codigo}</p>
                  </td>
                  <td className="table_body-td-orden">
                    <p>{item.tipo_orden}</p>
                  </td>
                  <td className="table_body-td">
                    <div className="actions-buttons-container">
                      <div className="ABM__edit-button">
                        <PopUpEdit datos={datos} item={item} codigo={item.tipo_codigo} handleUpdated={handleUpdated} />
                      </div>
                      <div className="ABM__watch-button">
                        <PopUpEye
                          abm_nombre={"de la Carrera"}
                          detalles={"de la Carrera"}
                          codigo={item.tipo_codigo}
                          parametro={item.tipo_parametro}
                          nombre_castellano={item.tipo_nombre_castellano}
                          orden={item.tipo_orden}
                          estado_nombre_castellano={
                            item.tipo_estado_nombre_castellano
                          }
                        />
                      </div>
                      <div className="ABM__delete-button">
                        <PopUpDelete item={item} codigo={item.tipo_codigo} handleUpdated={handleUpdated} />
                      </div>
                    </div>
                  </td>
                </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  );
};

export default ABMCarreras;
