export const columns = [
    { field: "evento_nombre", headerName: "Evento", flex: 1, headerAlign: "center", align: "center" },
    { field: "persona_nombre", headerName: "Apellido", sortable: false, flex: 1, headerAlign: "center", align: "center",
      valueGetter: (params) =>
        `${params.row.persona_nombre || ""} ${params.row.persona_apellido || ""}`,
    },
    { field: "tipo_carrera_nombre_castellano", headerName: "Carrera", flex: 1, headerAlign: "center", align: "center" },
    { field: "evento_formulario_nombre", headerName: "Nombre", flex: 1, headerAlign: "center", align: "center" },
    { field: "evento_formulario_email", headerName: "Correo", flex: 1, headerAlign: "center", align: "center" },
    { field: "evento_formulario_telefono", headerName: "Telefono", flex: 1, headerAlign: "center", align: "center" },
    { field: "alta_fecha_hora", headerName: "Fecha de Alta", flex: 1, headerAlign: "center", align: "center", type: 'dateTime',
      valueGetter: ({ value }) => value && new Date(value).toLocaleDateString('es-PY')},
  ];