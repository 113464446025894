import EditIcon from '@mui/icons-material/Edit';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import { useFormik } from 'formik';
import React, { useState } from 'react';
import carrierAdapter from '../../adapters/carriers';
import { putData } from "../../api/subscribers-api";
import { popupBoxContainer } from '../../styles/components/PopUpBoxcontainer';
import "../../styles/components/PopUpEdit.css";
import CustomLoadingButton from '../Buttons/CustomLoadingButton';

export default function PopUpEdit({ datos, item, codigo, handleUpdated }) {
  const [error, setError] = useState("");
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const formik = useFormik({
    initialValues: carrierAdapter(item),
    onSubmit: async values => {
      const response = await putData(`100/dominio/${codigo}`, 'PUT', JSON.stringify(values));
      if (response.code === 201 || response.code === 200) {
        setError("");
        setOpen(false);
        handleUpdated('actualizado');
      } else {
        setLoading(false)
        setError(response.message);
      }
      setLoading(false)
    },
  });

  return (
    <div>
      <Button onClick={handleOpen}><EditIcon sx={{ color: 'white'}}/></Button>
      <Modal
        keepMounted
        open={open}
        onClose={handleClose}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box sx={popupBoxContainer} className="popup_edit-container">
          <div>
            <form className='popup_edit-form' onSubmit={formik.handleSubmit}>
              <h2 className='popup_edit-h2'>Editar Carrera</h2>
              <div className='popup_edit-p'>
                <label className='popup_edit-label'>Nombre de carrera</label>
                <input
                  type='text'
                  className='popup_edit-input'
                  name="tipo_nombre_castellano"
                  value={formik.values.tipo_nombre_castellano}
                  onChange={formik.handleChange}
                />
              </div>
              <div className='popup_edit-p'>
                <label className='popup_edit-label'>Parámetro</label>
                <input
                  type='number'
                  className='popup_edit-input'
                  name="tipo_parametro"
                  value={formik.values.tipo_parametro}
                  onChange={formik.handleChange}
                  />
              </div>
              <div  className='popup_edit-p'>
                <label className='popup_edit-label'>Orden</label>
                <input
                  type='number'
                  className='popup_edit-input'
                  name="tipo_orden"
                  value={item.tipo_orden}
                  onChange={formik.handleChange}
                  />
              </div>
              <div  className='popup_edit-p'>
                <label className='popup_edit-label'>Estado</label>
                <select className='popup_edit-select' name="tipo_estado_parametro" defaultValue={formik.values.tipo_estado_nombre_castellano} onChange={formik.handleChange}>
                  {datos[1].map((item, index)=>
                    <option
                      className='popup_edit-option'
                      key={index}
                      value={item.tipo_parametro}
                    >
                      {item.tipo_nombre_castellano} {/* le viene del backend con este nombre de parametro: "tipo_parametro" pero se carga en el formulario con este nombre: "tipo_estado_parametro" */}
                    </option>
                  )}
                </select>
              </div>
              <div className="error-message">
                {error ? <p>{error}</p> : null}
              </div>
              <CustomLoadingButton
                loading={loading}
              >
                Actualizar
              </CustomLoadingButton>
            </form>
          </div>
        </Box>
      </Modal>
    </div>
  );
}