import AddIcon from '@mui/icons-material/Add';
import { Box, Button, Modal } from '@mui/material';
import { useFormik } from 'formik';
import React, { useState } from 'react';
import { postData } from "../../api/subscribers-api";
import "../../styles/components/PopUpAdd.css";
import { popupBoxContainer } from '../../styles/components/PopUpBoxcontainer';
import { initialvalues_pop_add_person } from '../../utils/initialvalues-pop-add-person';
import CustomLoadingButton from '../Buttons/CustomLoadingButton';

export default function PopUpAddPerson({ datos, handleUpdated }) {
  const [error, setError] = useState("");
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const formik = useFormik({
    initialValues: initialvalues_pop_add_person,
    onSubmit: async values => {
      setLoading(true)
      const parsedData = {...values, tipo_sexo_parametro: parseInt(values.tipo_sexo_parametro), tipo_estado_parametro: parseInt(values.tipo_estado_parametro)} //Por alguna razón me devuelve estos datos en string por eso me aseguro de parsearlos, mi yo del futuro cuando veas esto sabrás porque pasa eso
      const response = await postData("100/persona", 'POST', JSON.stringify(parsedData));
      if (response.code === 201 || response.code === 200) {
        setError("");
        handleUpdated('creado');
        setOpen(false);
      } else {
        setLoading(false)
        setError(response.message_error);
      }
    },
  });
  return (
    <div>
      <Button onClick={handleOpen}><AddIcon sx={{ color: 'darkslategray' }}/></Button>
      <Modal
        keepMounted
        open={open}
        onClose={handleClose}
      >
        <Box sx={popupBoxContainer}>
          <h2 className='popup_add-h2'>Agregar Persona</h2>
          <form className='popup_edit-form' onSubmit={formik.handleSubmit}>
            <div className='popup_add-input-container'>
              <label className='popup_add-label'>Nombre de la Persona </label>
              <input
                required
                type='text'
                className='popup_add-input'
                placeholder="Nombre"
                name='persona_nombre'
                onChange={formik.handleChange}
              />
            </div>
            <div className='popup_add-input-container'>
              <label className='popup_add-label'>Apellido de la Persona </label>
              <input
                required
                type='text'
                className='popup_add-input'
                placeholder="Apellido"
                name='persona_apellido'
                onChange={formik.handleChange}
              />
            </div>
            <div className='popup_add-input-container'>
              <label className='popup_add-label'>Número de documento</label>
              <input
                required
                type='text'
                className='popup_add-input'
                placeholder="Documento"
                name='persona_documento_numero'
                onChange={formik.handleChange}
              />
            </div>
            <div className='popup_add-input-container'>
              <label className='popup_add-label'>Correo Electronico</label>
              <input
                type='text'
                className='popup_add-input'
                placeholder="Email"
                name='persona_email'
                onChange={formik.handleChange}
              />
            </div>
            <div className='popup_add-input-container'>
              <label className='popup_add-label'>Número de orden</label>
              <input
                type='number'
                className='popup_add-input'
                placeholder="Orden"
                name='persona_orden'
                onChange={formik.handleChange}
              />
            </div>
            <div className='popup_add-input-container'>
              <label className='popup_add-label'>Número de teléfono</label>
              <input
                type='text'
                className='popup_add-input'
                placeholder="Telefono"
                name='persona_telefono_numero'
                onChange={formik.handleChange}
              />
            </div>
            <div className='popup_add-input-container'>
              <label className='popup_add-label'>Comentario</label>
              <input
                type='text'
                className='popup_add-input'
                placeholder="Observación"
                name='persona_observacion'
                onChange={formik.handleChange}
              />
            </div>
            <div  className='popup_edit-p'>
              <select className='popup_edit-select' name="tipo_estado_parametro" defaultValue='default' placeholder="Estado" required onChange={formik.handleChange}>
                <option className='popup_edit-option' disabled value='default' >Selecciona el estado</option>
                {datos[1].map((item, index)=>
                  <option
                    className='popup_edit-option'
                    key={index}
                    value={item.tipo_parametro}
                  >
                    {item.tipo_nombre_castellano} {/* le viene del backend con este nombre de parametro: "tipo_parametro" pero se carga en el formulario con este nombre: "tipo_estado_parametro" */}
                  </option>
                )}
                </select>
              </div>
            <div  className='popup_edit-p'>
              <select className='popup_edit-select' name="tipo_sexo_parametro" defaultValue='default' placeholder="Sexo" required onChange={formik.handleChange}>
                <option className='popup_edit-option' disabled value='default' >Selecciona el sexo</option>
                {datos[2].map((item, index)=>
                  <option
                    className='popup_edit-option'
                    key={index}
                    value={item.tipo_parametro}
                  >
                    {item.tipo_nombre_castellano} {/* le viene del backend con este nombre de parametro: "tipo_parametro" pero se carga en el formulario con este nombre: "tipo_estado_parametro" */}
                  </option>
                )}
                </select>
              </div>
            <div className="error-message_popup">{error ? <p>{error}</p> : null}</div>
            <CustomLoadingButton
              loading={loading}
            >
              Guardar
              </CustomLoadingButton>
          </form>
        </Box>
      </Modal>
    </div>
  );
}