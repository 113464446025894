import * as React from 'react';
import Paper from '@mui/material/Paper';
import "../styles/components/Charts.css";
import {
  Chart,
  BarSeries,
  Title,
  ArgumentAxis,
  ValueAxis,
  // Legend,
} from '@devexpress/dx-react-chart-material-ui';

export default function ChartCarrera ({ carreras }){
    return (
      <>
        {
          carreras ?
          <Paper className='charts__carreras-container'>
            <Chart className='charts__carreras'
              data={carreras}
              rotated
            >
              <BarSeries
                valueField="tipo_carrera_cantidad"
                argumentField="tipo_carrera_nombre"
                color='#F0006B'
                barWidth={0.5}
              />
              <ArgumentAxis />
              <ValueAxis />
              <Title text="Carreras" />
            </Chart>
          </Paper>
          :
          <h3>No hay carreras registradas en este rango de fechas, por favor elige otro rango</h3>
        }
      </>
    );

}
