import React from 'react';
import { Link, Outlet } from 'react-router-dom';
import logo from '../assets/logo.png';
import Footer from './Footer';
import Header from './Header';
import "../styles/routes/Layout.css";
import { Person as PersonIcon, AutoAwesomeMosaic as AutoAwesomeMosaicIcon, AccountBox as AccountBoxIcon, Festival as FestivalIcon, Assessment as  AssessmentIcon, Newspaper as NewspaperIcon, Badge as BadgeIcon} from '@mui/icons-material';

export default function Layout() {
    const user =  JSON.parse(localStorage.getItem('user_rol'));
    const person_code =  JSON.parse(localStorage.getItem('person_code'));
    const changeStyle = (isMenuOpened) => {
        const leftMenu = document.getElementById("left-menu");
        const logoContainer = document.getElementById("user-container");
        const leftMenuOption = Array.from(document.getElementsByClassName('admin-aside-p'));

        if (isMenuOpened) {
          leftMenu.style.width = "80px";
          leftMenuOption.forEach((item) => item.style.display = 'none');
          logoContainer.style.display = "none";
        } else {
          leftMenu.style.width = "250px";
          leftMenuOption.forEach((item) => item.style.display = 'flex');
          logoContainer.style.display = "flex";
        }
      };
    return (
        <>
            <div className="admin">
                <aside className="admin_aside" id="left-menu">
                    <div className="admin_aside-logo-container">
                        <h2 className="logo_empresa" id="logo-empresa">UMAX</h2>
                        <div className="admin_aside-user-container" id="user-container">
                            <img
                            className="admin_aside-user-logo"
                            src={logo}
                            alt="user-logo"
                            />
                        </div>
                    </div>
                    <div className="admin_aside-menu">
                        { user === 2 &&
                            <Link
                                to="/home/admin"
                                className="admin_aside-link"
                            >
                                <AssessmentIcon className="admin_aside-icon" />
                                <p className="admin-aside-p" id="p-three">
                                    Tablero
                                </p>
                            </Link>
                        }
                        <Link
                            to="/home/admin/inscriptos"
                            className="admin_aside-link"
                        >
                            <NewspaperIcon className="admin_aside-icon" />
                            <p className="admin-aside-p" id="p-three">
                                Personas Inscriptas
                            </p>
                        </Link>
                        { user === 1 &&
                        <Link
                            to="/home/admin/inscripcion"
                            className="admin_aside-link"
                        >
                            <BadgeIcon className="admin_aside-icon" />
                            <p className="admin-aside-p" id="p-three">
                                Inscripción
                            </p>
                        </Link>
                        }
                        { user === 2 && person_code === 12 &&
                            <Link to="/home/admin/carrera"
                                className="admin_aside-link"
                            >
                                <AutoAwesomeMosaicIcon className="admin_aside-icon" />
                                <p className="admin-aside-p" id="p-three">
                                    ABM Carreras
                                </p>
                            </Link>
                        }
                        { user === 2 &&
                            <Link to="/home/admin/evento"
                                className="admin_aside-link"
                            >
                                <FestivalIcon className="admin_aside-icon" />
                                <p className="admin-aside-p" id="p-three">
                                    ABM Evento
                                </p>
                            </Link>
                        }
                        { user === 2 && person_code === 12 &&
                            <Link to="/home/admin/usuario"
                                className="admin_aside-link"
                            >
                                <AccountBoxIcon className="admin_aside-icon" />
                                <p className="admin-aside-p" id="p-three">
                                    ABM Usuario
                                </p>
                            </Link>
                        }
                        { user === 2 && person_code === 12 &&
                        <Link to="/home/admin/persona"
                            className="admin_aside-link"
                        >
                            <PersonIcon className="admin_aside-icon" />
                            <p className="admin-aside-p" id="p-three">
                                ABM Persona
                            </p>
                        </Link>
                        }
                    </div>
                </aside>
                <section className="admin_section-main-container">
                    <Header changeStyle={changeStyle} />
                    <Outlet/>
                    <Footer />
                </section>
            </div>
        </>
    );
}