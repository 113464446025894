import React from "react";
import GlobalAlert from "./components/GlobalAlert";
import ContextProvider from "./context/context-provider";
import Router from "./Router";


function App() {
  return (
    <ContextProvider className="App">
      <Router/>
      <GlobalAlert/>
    </ContextProvider>
  );
}

export default App;
