export default function userAdapter(item) {
  const usuario_codigo = parseInt(localStorage.getItem("userData"));
  const date_time = new Date().toLocaleString();
  return {
    auditoria_ip: "0.0.0.0",
    auditoria_usuario_codigo: usuario_codigo,
    auditoria_incidencia: '',
    auditoria_fecha_hora: date_time,
    tipo_accion_codigo: 1, // Number. requerido
    tipo_estado_parametro: item.tipo_estado_parametro, // Number. requerido
    tipo_rol_parametro: item.tipo_rol_parametro, // Number. requerido
    persona_codigo: parseInt(item.persona_codigo), // Number. requerido
    usuario_orden: item.usuario_orden, // Number - requerido
    usuario_nombre: item.usuario_nombre, // texto - requerido
    usuario_contrasenha: item.usuario_contrasenha, // texto - requerido
    usuario_observacion: item.usuario_observacion, // texto - opcional
  };
}
