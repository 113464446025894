const usuario_codigo = parseInt((localStorage.getItem('userData')));
const date_time = new Date().toLocaleString();

export const initialvalues_pop_add_user = {
    tipo_estado_parametro: 0, // Number -requerido
    tipo_rol_parametro: 0, // Number -requerido
    persona_codigo: 0, // Number- requerido
    usuario_orden: 0, // Number- opcional
    usuario_nombre: '', //texto- requerido
    usuario_contrasenha: '', //texto- requerido
    usuario_observacion: '', //texto- opcional
    auditoria_ip: '0.0.0.0',
    auditoria_incidencia: '',
    auditoria_usuario_codigo: usuario_codigo,
    auditoria_fecha_hora: date_time,
}