import DeleteIcon from '@mui/icons-material/Delete';
import Button from '@mui/material/Button';
import React, { useState } from 'react';
import { putData } from "../../api/subscribers-api";

export default function PopUpDelete({ item, codigo, handleUpdated }) {
  const [error, setError] = useState("No se pudo anular");


  const handleSubmit = async () => {
    let body = JSON.stringify({
      auditoria_ip: '0.0.0.0',
      tipo_dominio: "CIMEVENTOFORMULARIOCARRERA",
      tipo_accion_codigo: 2,
      auditoria_usuario_codigo:item.auditoria_usuario_codigo,
      tipo_nombre_ingles: item.tipo_nombre_ingles,
      tipo_nombre_castellano: item.tipo_nombre_castellano,
      tipo_nombre_portugues: item.tipo_nombre_portugues,
      tipo_estado_parametro: 2,
      tipo_codigo: codigo
    })
    try {
      const response = await putData(`100/dominio/${codigo}`, 'PUT', body);
      if (response.code === 201 || response.code === 200) {
        setError("");
        handleUpdated('eliminado');
      } else {
        setError(error);
      }
    }
    catch (err) {
      setError(err);
    }
  };

  return (
    <div>
      <Button onClick={handleSubmit}><DeleteIcon sx={{ color: 'white' }}/></Button>
    </div>
  );
}