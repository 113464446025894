import { DataGrid } from "@mui/x-data-grid";
import React from "react";
import "../styles/routes/SubscribersList.css";
import { columns } from "../utils/SubscriberslistColumn";
import SubscribersNavbar from "./SubscribersNavbar";

export default function SubscribersList2({ data }) {
  const user_rol =  JSON.parse(localStorage.getItem('user_rol'));
  const persona_codigo =  JSON.parse(localStorage.getItem('person_code'));

  const rows = data.filter(item => user_rol === 1 ? item.persona_codigo === persona_codigo && item.evento_asesor_codigo !== 14 && item.evento_asesor_codigo !== 15 && item.evento_asesor_codigo !== 1 : item.evento_asesor_codigo !== 14 && item.evento_asesor_codigo !== 15 && item.evento_asesor_codigo !== 1).map(item => item);
  const PAGE_GAP = 60 + 2 * 10;
  const height = window.innerHeight - PAGE_GAP - 120;
  return (
    <div style={{height}} className="abm__container">
      <SubscribersNavbar data={rows}/>
      <DataGrid
        getRowId={(row) => row.evento_formulario_codigo}
        rows={rows}
        columns={columns}
      />
    </div>
  );
}