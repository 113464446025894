import React from "react";
import "../styles/components/DashboardData.css";

import ChartEvento from "./ChartEvento";
import ChartAsesor from "./ChartAsesor";
import ChartCarrera from "./ChartCarrera";

const DashboardData = ({ data }) => {
  const noneNullElement = data.every((item)=> !!item);
  return (
    <div className="charts__container">{
      noneNullElement ?
      <>
        <ChartEvento eventos={data[0]}/>
        <ChartAsesor asesores={data[2]}/>
        <ChartCarrera carreras={data[1]}/>
      </>
      :
      <h3>No hay datos en este rango de fechas, por favor elige otro rango</h3>
}
    </div>
  );
}

export default DashboardData;