import React, { Fragment, useEffect, useState } from "react";
import { getData } from "../api/subscribers-api";
import DashboardData from "../components/DashboardData";
import AlignedBox from "../components/mui/AlignedBox";
import AlignedSpinner from "../components/mui/AlignedSpinner";
import "../styles/routes/SubscribersList.css";

const DashboardDataRender = ({desde, hasta}) => {
  const initialState = { isLoading: true, error: null, data: null}
  const [state, setState] = useState(initialState)

  useEffect(()=>{
    let isMounted = true;
    setState(initialState)
    const mainData = [
      getData(`200/eventoformulario/chart/evento/fechadesde/${desde}/fechahasta/${hasta}`, 'GET'),
      getData(`200/eventoformulario/chart/tipocarrera/fechadesde/${desde}/fechahasta/${hasta}`, 'GET'),
      getData(`200/eventoformulario/chart/asesor/fechadesde/${desde}/fechahasta/${hasta}`, 'GET')
    ]
    Promise.all(mainData)
      .then((data)=> {
        if (isMounted){
          setState({ isLoading: false, error: null, data: data })
        }
      })
      .catch(()=> {
        setState({ isLoading: false, error: " No hay datos, por favor elige otro rango de fechas", data: null })
      })
    return () => { isMounted = false };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [desde, hasta])

  return (
    <Fragment>
      {state.isLoading && <AlignedSpinner/>}
      {state.error && <AlignedBox>{state.error}</AlignedBox>}
      {state.data && <DashboardData data={state.data} />}
    </Fragment>
    );
}

export default DashboardDataRender;
