import AddCircleIcon from "@mui/icons-material/AddCircle";
import React from "react";
import "../../styles/components/ABM.css";
import AddCarrera from "../AddCarrera";
import PopUpAddUser from "../popups/PopUpAddUser";
import PopUpDeleteUser from "../popups/PopUpDeleteUser";
import PopUpEditUser from "../popups/PopUpEditUser";
import PopUpEye from "../popups/PopUpEYe";

const ABMUsuario = ({ datos, handleUpdated }) => {
  let isAddOpened = true;

  const changeStyle = () => {
    isAddOpened = !isAddOpened;
    const addWrapper = document.getElementById("add-wrapper");
    if (isAddOpened) {
      addWrapper.style.display = "none";
    } else {
      addWrapper.style.display = "flex";
    }
  };
  return (
    <div className="abm__container">
      <nav className="abm__nav">
        <h2>ABM Usuario</h2>
        <div className="ABM__add-button">
          <PopUpAddUser datos={datos} handleUpdated={handleUpdated} />
        </div>
      </nav>
      <div className="ABM__add-wrapper" id="add-wrapper">
        <AddCarrera />
        <button className="ABM__close-button" onClick={changeStyle}>
          <AddCircleIcon className="close-button" />
        </button>
      </div>
      <table className="table">
        <thead className="table_head">
          <tr>
            <th>
              <p>Código</p>
            </th>
            <th>
              <p>Estado</p>
            </th>
            <th className="table_body-th-orden">
              <p>Orden</p>
            </th>
            <th>
              <p>Usuario</p>
            </th>
            <th className="table_body-th-orden">
              <p>Rol del usuario</p>
            </th>
            <th className="table_body-th-orden">
              <p>Nombre</p>
            </th>
            <th>
              <p>Acción</p>
            </th>
          </tr>
        </thead>
        <tbody className="table_body">
          {datos[0].filter(item => item.tipo_estado_parametro !== 2).map((item, index) => {
            return (
                <tr className="table_body-tr" key={index}>
                  <td className="table_body-td">
                    <p>{item.usuario_codigo}</p>
                  </td>
                  <td className="table_body-td">
                    <p>{item.tipo_estado_nombre_castellano}</p>
                  </td>
                  <td className="table_body-td-orden">
                    <p>{item.persona_orden}</p>
                  </td>
                  <td className="table_body-td">
                    <p>{item.persona_email}</p>
                  </td>
                  <td className="table_body-td-orden">
                    <p>{item.tipo_rol_nombre_castellano}</p>
                  </td>
                  <td className="table_body-td-orden">
                    <p>{item.persona_nombre}</p>
                  </td>
                  <td className="table_body-td">
                    <div className="actions-buttons-container">
                      <div className="ABM__edit-button">
                        <PopUpEditUser rol={datos[1]} estados={datos[2]} persona={datos[3]} item={item} codigo={item.usuario_codigo} handleUpdated={handleUpdated} />
                      </div>
                      <div className="ABM__watch-button">
                        <PopUpEye
                          abm_nombre={"del usuario"}
                          detalles={"del Usuario"}
                          codigo={item.persona_codigo}
                          nombre_castellano={item.persona_email}
                          orden={item.persona_orden}
                          persona={item.persona_nombre}
                          rol={item.tipo_rol_nombre_castellano}
                          estado_nombre_castellano={
                            item.tipo_estado_nombre_castellano
                          }
                        />
                      </div>
                      <div className="ABM__delete-button">
                        <PopUpDeleteUser
                          item={item}
                          codigo={item.usuario_codigo}
                          handleUpdated={handleUpdated}
                        />
                      </div>
                    </div>
                  </td>
                </tr>
              )}
            )
          }
        </tbody>
      </table>
    </div>
  );
};

export default ABMUsuario;
