import React, { Fragment, useEffect, useState } from "react";
import { getData } from "../api/subscribers-api";
import AlignedBox from "../components/mui/AlignedBox";
import AlignedSpinner from "../components/mui/AlignedSpinner";
import SubscribeForm from "../components/SubscribeForm";
import "../styles/routes/SubscribersListRender.css";


const FormDataRender = () => {
  const initialState = { isLoading: true, error: null, data: null}
  const [state, setState] = useState(initialState)

  useEffect(()=>{
    setState(initialState)
    const mainData = [
      getData(`200/evento/listado`, 'GET'), // evento_codigo, evento_nombre
      getData(`200/eventoasesor/listado`, 'GET'), // persona_codigo, persona_nombre, persona_apellido
      getData(`100/dominio/valor/CIMEVENTOFORMULARIOCARRERA`, 'GET') // tipo_parametro, tipo_nombre
    ]
    Promise.all(mainData)
    .then((data)=> {
      setState({ isLoading: false, error: null, data: data })
    })
    .catch((error)=> {
      setState({ isLoading: false, error: "null, nothing was returned", data: null })
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <Fragment>
      {state.isLoading && <AlignedSpinner/>}
      {state.error && <AlignedBox>{state.error}</AlignedBox>}
      {state.data && <SubscribeForm data={state.data} />}
    </Fragment>
  );
}

export default FormDataRender;
