import { LoadingButton } from "@mui/lab";
import { CircularProgress } from "@mui/material";

const CustomLoadingButton = ({ children, ...props }) => {
  return (
    <LoadingButton
      type="submit"
      loading={props.loading}
      loadingIndicator={<CircularProgress sx={{ color: "white" }} size={18} />}
      sx={{
        width: '150px',
        backgroundColor: "#d2322d",
        alignSelf: "center",
        textTransform: "none",
        color: "white",
        "&:hover": {
          backgroundColor: "#e73e39",
          boxShadow: "none",
        },
      }}
    >
      {children}
    </LoadingButton>
  );
};
export default CustomLoadingButton;
