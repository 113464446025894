
export default function carrierAdapter (item) {
    const usuario_codigo = parseInt(localStorage.getItem('userData'));
    const date_time = new Date().toLocaleString();
    return {
        auditoria_ip: '0.0.0.0',
        auditoria_usuario_codigo: usuario_codigo,
        auditoria_incidencia: item.auditoria_incidencia,
        auditoria_fecha_hora: date_time,
        tipo_estado_parametro: item.tipo_estado_parametro,
        tipo_accion_codigo: 1, // Number. requerido
        tipo_nombre_ingles: item.tipo_nombre_ingles,
        tipo_nombre_castellano: item.tipo_nombre_castellano,
        tipo_nombre_portugues: item.tipo_nombre_portugues,
        tipo_dominio: "CIMEVENTOFORMULARIOCARRERA",
        tipo_grupo_parametro: 0, //= numerico - opcional
        tipo_grupo_dominio: '', //= texto - opcional
        tipo_orden: 1, //= numerico - opcional
        tipo_parametro: item.tipo_parametro, //= numerico - opcional
        tipo_clase_activo: '', //= texto - opcional
        tipo_clase_inactivo: '', //= texto - opcional
        tipo_estilo_activo: '', //= texto - opcional
        tipo_estilo_inactivo: '', //= texto - opcional
        tipo_background_activo: '', //= texto - opcional
        tipo_background_inactivo: '', //= texto - opcional
        tipo_color_activo: '', //= texto - opcional
        tipo_color_inactivo: '', //= texto - opcional
        tipo_imagen_activo: '', //= texto - opcional
        tipo_imagen_inactivo: '',  //= texto - opcional
        tipo_path: '',
        tipo_equivalencia: '',
        tipo_observacion: '',
    }
}