import { createContext, useContext, useState } from "react";

export const AppContext = createContext();
export const useMyContext = () => useContext(AppContext);

const ContextProvider = ({ children }) => {
  const [updated, setUpdated] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [abmAlert, setAbmAlert] = useState('creado');
  const [pageSize, setPageSize] = useState(25);
  const [dataLength, setDataLength] = useState(0);
  return (
    <AppContext.Provider value={{updated, setUpdated, openAlert, setOpenAlert, abmAlert, setAbmAlert, pageSize, setPageSize, dataLength, setDataLength}}>
      {children}
    </AppContext.Provider>
  );
};

export default ContextProvider;
