export default function personAdapter(item) {
  const usuario_codigo = parseInt(localStorage.getItem("userData"));
  const date_time = new Date().toLocaleString();
  return {
    auditoria_ip: "0.0.0.0",
    auditoria_usuario_codigo: usuario_codigo,
    auditoria_incidencia: '',
    auditoria_fecha_hora: date_time,
    tipo_accion_codigo: 1, // Number. requerido
    tipo_estado_parametro: parseInt(item.tipo_estado_parametro), // Number. requerido
    tipo_sexo_parametro: parseInt(item.tipo_sexo_parametro), // Number. requerido
    persona_orden: item.persona_orden, //= numerico - requerido
    persona_documento_numero: item.persona_documento, //= texto - requerido
    persona_nombre: item.persona_nombre, //= texto - requerido
    persona_apellido: item.persona_apellido, //= texto - requerido
    persona_email: item.persona_email, //= texto - opcional
    persona_telefono_numero:item.persona_telefono_numero,//= texto - opcional
    persona_observacion: item.persona_observacion, //= texto - opcional
  };
}
