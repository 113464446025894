import { Alert, Snackbar } from "@mui/material";
import { useMyContext } from "../context/context-provider";

const GlobalAlert = () => {
    const {openAlert, setOpenAlert, abmAlert} = useMyContext();
    const handleSnackbarClose = () => {
        setOpenAlert(false);
      };
    return (
        <Snackbar open={openAlert} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} autoHideDuration={3000} onClose={handleSnackbarClose}>
            <Alert onClose={handleSnackbarClose} severity="success" sx={{ width: '100%' }}>
                Registro {abmAlert} con exito!
            </Alert>
        </Snackbar>
    );
};

export default GlobalAlert;
