import React from "react";
import "../styles/components/AddCarrera.css";

const AddCarrera = () => {
  return (
    <div className="addCarrera__container">
      <h2 className="addCarrera-h2">Agregar Carrera</h2>
      <form type="POST" className="addCarrera-form">
        <input className="addCarrera-button-input"  type="text" name="codigo" placeholder="Clase (Activo)"/>
        <input className="addCarrera-button-input" type="text" name="codigo" placeholder="Clase (Inactivo)"/>
        <input className="addCarrera-button-input" type="text" placeholder="Style (Activo)"/>
        <input className="addCarrera-button-input" type="text" placeholder="Style (Inactivo)"/>
        <input className="addCarrera-button-input" type="text" name="codigo" placeholder="Background (Activo)"/>
        <input className="addCarrera-button-input" type="text" placeholder="Background (Inactivo)"/>
        <input className="addCarrera-button-input" type="text" name="codigo" placeholder="Color (Activo)"/>
        <input className="addCarrera-button-input" type="text" placeholder="Color (Inactivo)"/>
        <input className="addCarrera-button-input" type="text" name="codigo" placeholder="Icono (Activo)"/>
        <input className="addCarrera-button-input" type="text" placeholder="Icono (Inactivo)"/>
        <input className="addCarrera-button-input" type="text" placeholder="Estado"/>
        <input className="addCarrera-button-input" type="text" placeholder="Parametro"/>
        <input className="addCarrera-button-input-carrera" type="text" placeholder="Nombre de Carrera"/>
        <textarea className="addCarrera-button-textarea" type="text" name="codigo" placeholder="Comentario"/>
        <input className="addCarrera-button-submit" type="submit" value="Agregar"/>
      </form>
    </div>
  );
}

export default AddCarrera;