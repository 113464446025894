import React, { Fragment, useEffect, useState } from "react";
import { getData } from "../api/subscribers-api";
import ABMUsuario from "../components/ABM_components/ABMUsuario";
import AlignedSpinner from "../components/mui/AlignedSpinner";
import { useMyContext } from "../context/context-provider";


const ABMUserRender = () => {
  const initialState = { isLoading: true, error: null, data: null}
  const [state, setState] = useState(initialState);
  const {setOpenAlert, updated, setUpdated, setAbmAlert} = useMyContext();

  const handleUpdated = (abmType) => {
    setOpenAlert(true);
    setAbmAlert(abmType)
    updated ? setUpdated(false) : setUpdated(true);
  }
  useEffect(()=>{
    let isMounted = true;
    setState(initialState)
    const mainData = [
      getData(`100/usuario/listado`, 'GET'),
      getData(`100/dominio/valor/ADMUSUARIOROL`, 'GET'),
      getData(`100/dominio/valor/ADMPERSONAESTADO`, 'GET'),
      getData(`100/persona/listado`, 'GET'),
      getData(`200/eventoformulario/listado`, 'GET'),
    ]
    Promise.all(mainData)
    .then((data)=> {
      if (isMounted){
        setState({ isLoading: false, error: null, data: data })
      }
    })
    .catch((error)=> {
      setState({ isLoading: false, error: "Sorry, nothing was returned", data: null })
    })
    return () => { isMounted = false };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updated])

  return (
    <Fragment>
      {state.isLoading && <AlignedSpinner/>}
      {state.error && <h2>{state.error}</h2>}
      {state.data && <ABMUsuario datos={state.data} handleUpdated={handleUpdated} />}
    </Fragment>
    );
}

export default ABMUserRender;
