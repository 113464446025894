const usuario_codigo = parseInt((localStorage.getItem('userData')));

export const initialvalues_pop_add = {
    auditoria_ip: '0.0.0.0',
    auditoria_incidencia: '',
    auditoria_usuario_codigo: usuario_codigo,
    tipo_estado_parametro: 1,
    tipo_accion_codigo: 1,
    tipo_nombre_ingles: '', //= texto - requerido
    tipo_nombre_castellano: '', //= texto - requerido
    tipo_nombre_portugues: '', //= texto - requerido
    tipo_dominio: "CIMEVENTOFORMULARIOCARRERA",
    tipo_grupo_parametro: 0, //= numerico - opcional
    tipo_grupo_dominio: '', //= texto - opcional
    tipo_orden: 1, //= numerico - opcional
    tipo_parametro: 0, //= numerico - opcional
    tipo_clase_activo: '', //= texto - opcional
    tipo_clase_inactivo: '', //= texto - opcional
    tipo_estilo_activo: '', //= texto - opcional
    tipo_estilo_inactivo: '', //= texto - opcional
    tipo_background_activo: '', //= texto - opcional
    tipo_background_inactivo: '', //= texto - opcional
    tipo_color_activo: '', //= texto - opcional
    tipo_color_inactivo: '', //= texto - opcional
    tipo_imagen_activo: '', //= texto - opcional
    tipo_imagen_inactivo: '',  //= texto - opcional
    tipo_path: '',
    tipo_equivalencia: '',
    tipo_observacion: '',
}