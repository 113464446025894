
export default function eventAdapter (item) {
    const usuario_codigo = (localStorage.getItem('userData'));
    const date_time = new Date().toLocaleString();
    return {
        auditoria_ip: '0.0.0.0',
        auditoria_usuario_codigo: usuario_codigo,
        auditoria_incidencia: '',
        auditoria_fecha_hora: date_time,
        tipo_accion_codigo: 1,
        tipo_estado_parametro: item.tipo_estado_parametro,
        evento_orden: item.evento_orden,
        evento_codigo: item.evento_codigo,
        evento_nombre: item.evento_nombre,
        evento_fecha: item.evento_fecha_1,
        evento_observacion: item.evento_observacion
    }
}