import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Dashboard from "./components/Dashboard";
import Layout from "./components/Layout";
import Login from "./components/Login";
import ABMCarrierRender from "./utils/ABMCarrierRender";
import ABMPersonRender from "./utils/ABMPersonRender";
//import DashboardDataRender from "./utils/DashboardDataRender";
import ABMEventRender from "./utils/ABMEventRender";
import ABMUserRender from "./utils/ABMUserRender";
import FormDataRender from "./utils/FormDataRender";
import SubscribersListRender2 from "./utils/SubscribersListRender2";

function Router() {
  return (
    <BrowserRouter>
      <Routes>
        <Route index element={<Login />} />
        <Route path="/home" element={<Layout />}>
          <Route path="admin">
            <Route index element={<Dashboard />} />
            <Route path="inscriptos" element={<SubscribersListRender2 />} />
            <Route path="inscripcion" element={<FormDataRender />} />
            <Route path="carrera" element={<ABMCarrierRender />} />
            <Route path="evento" element={<ABMEventRender />} />
            <Route path="usuario" element={<ABMUserRender />} />
            <Route path="persona" element={<ABMPersonRender />} />
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default Router;
