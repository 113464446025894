import DeleteIcon from '@mui/icons-material/Delete';
import Button from '@mui/material/Button';
import { useFormik } from 'formik';
import React, { useState } from 'react';
import userAdapter from '../../adapters/users';
import { putData } from "../../api/subscribers-api";


export default function PopUpDeleteUser({ item, codigo, handleUpdated }) {
  const [error, setError] = useState("No se pudo anular");
  const [open, setOpen] = useState(false);

  const formik = useFormik({
    initialValues: userAdapter(item),
    onSubmit: async values => {
      const data = {...values, tipo_estado_parametro: 2}
      const response = await putData(`100/usuario/${codigo}`, 'PUT', JSON.stringify(data));
      if (response.code === 201 || response.code === 200) {
        setError("");
        handleUpdated('eliminado');
        setOpen(open)
      } else {
        setError(error, response.message_short);
      }
    },
  });

  return (
    <div>
      <Button onClick={formik.handleSubmit}><DeleteIcon sx={{ color: 'white' }}/></Button>
    </div>
  );
}