import React, { Fragment, useEffect, useState } from "react";
import { getData } from "../api/subscribers-api";
import AlignedBox from "../components/mui/AlignedBox";
import AlignedSpinner from "../components/mui/AlignedSpinner";
import SubscribersList2 from "../components/SubscribersList2";
import "../styles/routes/SubscribersList.css";

const SubscribersListRender2 = () => {
  const initialState = { isLoading: true, error: null, data: null}
  const [state, setState] = useState(initialState);

  useEffect(()=>{
    setState(initialState)
      getData(`200/eventoformulario/listado?page=1&limit=100000`, 'GET')
      .then((subscribers)=> {
        setState({ isLoading: false, error: null, data: subscribers })
      })
      .catch((error)=> {
        setState({ isLoading: false, error: "null, nothing was returned", data: null })
      })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <Fragment>
      {state.isLoading && <AlignedSpinner/>}
      {state.error && <AlignedBox>{state.error}</AlignedBox>}
      {state.data && <SubscribersList2 data={state.data} />}
    </Fragment>
    );
}

export default SubscribersListRender2;