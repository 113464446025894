import React from "react";
import "../styles/components/Footer.css";

export default function Footer (){
  return (
    <footer className="footer">
      <hr className='footer-middle-line' />
      <div className="footer-p-container">
        <p className='footer-p'>Copyright © 2022. All rights reserved.</p>
        <p className='footer-p'>CEROUNO Labs</p>
      </div>
    </footer>
  );
};