const usuario_codigo = parseInt((localStorage.getItem('userData')));

export const initialvalues_pop_add_person = {
    auditoria_ip: '0.0.0.0',
    auditoria_incidencia: '',
    auditoria_usuario_codigo: usuario_codigo,
    tipo_estado_parametro: 1,
    tipo_sexo_parametro: 0,
    persona_orden: 0, //= numerico - requerido
    persona_documento_numero: '', //= texto - requerido
    persona_nombre: '', //= texto - requerido
    persona_apellido: '', //= texto - requerido
    persona_email: '', //= texto - opcional
    persona_telefono_numero: '', //= texto - opcional
    persona_observacion: '', //= texto - opcional
}