import DeleteIcon from '@mui/icons-material/Delete';
import Button from '@mui/material/Button';
import { useFormik } from 'formik';
import React, { useState } from 'react';
import personAdapter from '../../adapters/person';
import { putData } from "../../api/subscribers-api";

export default function PopUpDeletePerson ({ item, codigo, handleUpdated }) {
  const [error, setError] = useState("No se pudo anular");

  const formik = useFormik({
    initialValues: personAdapter(item),
    onSubmit: async values => {
      const data = {...values, tipo_estado_parametro: 2}
      const response = await putData(`100/persona/${codigo}`, 'PUT', JSON.stringify(data));
      if (response.code === 201 || response.code === 200) {
        setError("");
        handleUpdated('eliminado');
      } else {
        setError(error, response.message_short);
      }
    },
  });

  return (
    <div>
      <Button onClick={formik.handleSubmit}><DeleteIcon sx={{ color: 'white' }}/></Button>
    </div>
  );
}