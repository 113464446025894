const usuario_codigo = (localStorage.getItem('userData'));
const date_time = new Date().toLocaleString();

export const initialvalues_pop_add_event = {
    auditoria_ip: '0.0.0.0',
    auditoria_usuario_codigo: usuario_codigo,
    auditoria_incidencia: '',
    auditoria_fecha_hora: date_time,
    tipo_estado_parametro: 0,
    evento_orden: 0,
    evento_nombre: '',
    evento_fecha: '',
    evento_observacion: ''
}