import { Edit } from '@mui/icons-material';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import { Box } from '@mui/system';
import { useFormik } from 'formik';
import React, { useState } from 'react';
import personAdapter from '../../adapters/person';
import { putData } from "../../api/subscribers-api";
import "../../styles/components/PopUpAdd.css";
import { popupBoxContainer } from '../../styles/components/PopUpBoxcontainer';
import CustomLoadingButton from '../Buttons/CustomLoadingButton';

export default function PopUpEditPerson({ datos, item, codigo, handleUpdated }) {
  const [error, setError] = useState("");
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const formik = useFormik({
    initialValues: personAdapter(item),
    onSubmit: async values => {
      const parsedData = {...values, tipo_sexo_parametro: parseInt(values.tipo_sexo_parametro), tipo_estado_parametro: parseInt(values.tipo_estado_parametro)}
      setLoading(true)
      const response = await putData(`100/persona/${codigo}`, 'PUT', JSON.stringify(parsedData));
      if (response.code === 201 || response.code === 200) {
        setError("");
        handleUpdated('actualizado');
        setOpen(false)
      } else {
        setLoading(false)
        setError(response.message_short);
      }
      setLoading(false)
    },
  });

  return (
    <div>
      <Button onClick={handleOpen}><Edit sx={{ color: 'white' }}/></Button>
      <Modal
        keepMounted
        open={open}
        onClose={handleClose}
      >
        <Box sx={popupBoxContainer}>
          <h2 className='popup_add-h2'>Editar Persona</h2>
          <form className='popup_edit-form' onSubmit={formik.handleSubmit}>
          <div className='popup_add-input-container'>
              <label className='popup_add-label'>Nombre de la Persona </label>
              <input
                type='text'
                className='popup_add-input'
                placeholder="Nombre"
                name='persona_nombre'
                value={formik.values.persona_nombre}
                onChange={formik.handleChange}
              />
            </div>
            <div className='popup_add-input-container'>
              <label className='popup_add-label'>Apellido de la Persona </label>
              <input
                type='text'
                className='popup_add-input'
                placeholder="Apellido"
                name='persona_apellido'
                value={formik.values.persona_apellido}
                onChange={formik.handleChange}
              />
            </div>
            <div className='popup_add-input-container'>
              <label className='popup_add-label'>Número de documento</label>
              <input
                required
                type='text'
                className='popup_add-input'
                placeholder="Documento"
                name='persona_documento_numero'
                value={formik.values.persona_documento_numero ? formik.values.persona_documento_numero : '0'}
                onChange={formik.handleChange}
              />
            </div>
            <div className='popup_add-input-container'>
              <label className='popup_add-label'>Correo Electronico</label>
              <input
                type='text'
                className='popup_add-input'
                placeholder="Email"
                name='persona_email'
                value={formik.values.persona_email}
                onChange={formik.handleChange}
              />
            </div>
            <div className='popup_add-input-container'>
              <label className='popup_add-label'>Número de orden</label>
              <input
                type='number'
                className='popup_add-input'
                placeholder="Orden"
                name='persona_orden'
                value={formik.values.persona_orden}
                onChange={formik.handleChange}
              />
            </div>
            <div className='popup_add-input-container'>
              <label className='popup_add-label'>Número de teléfono</label>
              <input
                type='text'
                className='popup_add-input'
                placeholder="Telefono"
                name='persona_telefono_numero'
                value={formik.values.persona_telefono_numero ? formik.values.persona_telefono_numero : '0'}
                onChange={formik.handleChange}
              />
            </div>
            <div className='popup_add-input-container'>
              <label className='popup_add-label'>Comentario</label>
              <input
                type='text'
                className='popup_add-input'
                placeholder="Observación"
                name='persona_observacion'
                value={formik.values.persona_observacion ? formik.values.persona_observacion : 'No hay comentarios'}
                onChange={formik.handleChange}
              />
            </div>
            <div  className='popup_edit-p'>
              <label className='popup_edit-label'>Estado</label>
              <select className='popup_edit-select' name="tipo_estado_parametro" value={formik.values.tipo_estado_parametro} onChange={formik.handleChange}>
                <option className='popup_edit-option' disabled >Selecciona un rol</option>
                {datos[1].filter(item => item.tipo_estado_parametro !== 2).map((item, index)=>
                  <option
                    className='popup_edit-option'
                    key={index}
                    value={item.tipo_parametro}
                  >
                    {item.tipo_nombre_castellano} {/* le viene del backend con este nombre de parametro: "tipo_parametro" pero se carga en el formulario con este nombre: "tipo_estado_parametro" */}
                  </option>
                )}
                </select>
              </div>
              <div  className='popup_edit-p'>
              <label className='popup_edit-label'>Sexo</label>
              <select className='popup_edit-select' name="tipo_sexo_parametro" value={formik.values.tipo_sexo_parametro} onChange={formik.handleChange}>
                <option className='popup_edit-option' disabled >Selecciona un sexo</option>
                {datos[2].filter(item => item.tipo_estado_parametro !== 2).map((item, index)=>
                  <option
                    className='popup_edit-option'
                    key={index}
                    value={item.tipo_parametro}
                  >
                    {item.tipo_nombre_castellano} {/* le viene del backend con este nombre de parametro: "tipo_parametro" pero se carga en el formulario con este nombre: "tipo_estado_parametro" */}
                  </option>
                )}
                </select>
              </div>
            <div className="error-message_popup">{error ? <p>{error}</p> : null}</div>
            <CustomLoadingButton
              loading={loading}
            >
              Guardar
              </CustomLoadingButton>
          </form>
        </Box>
      </Modal>
    </div>
  );
}
