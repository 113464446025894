import React from 'react';
import ExportCSVConverter from "../utils/ExportCSVConverter";
import { ExportReactCSV } from "./ExportReactCSV";
import "../styles/routes/SubscribersList.css";

function SubscribersNavbar({data}) {
    let newArr = ExportCSVConverter(data);
  return (
    <>
        <nav className="abm__nav">
        <h2>Lista de inscriptos</h2>
        <div className="ABM__add-button">
            <div className="subscribers-headers-btn">
            <ExportReactCSV csvData={newArr} fileName={"Archivo Excel"} />
            </div>
        </div>
        </nav>
    </>
  )
}

export default SubscribersNavbar