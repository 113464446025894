import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import "../../styles/components/PopUpEye.css";
import { popupBoxContainer } from '../../styles/components/PopUpBoxcontainer';

export default function PopUpEye({ abm_nombre, detalles, codigo, nombre_castellano, parametro, orden, persona, rol, sexo, estado_nombre_castellano  }) {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
      <Button onClick={handleOpen}><RemoveRedEyeIcon sx={{ color: 'white' }}/></Button>
      <Modal
        keepMounted
        open={open}
        onClose={handleClose}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box sx={popupBoxContainer}>

          <div className='popup_eye-container'>
            <h2 className='popup_eye-h2'>Detalles {detalles}</h2>
              <div className='popup_eye-wrapper'>
                <label className='popup_eye-label'>Nombre {abm_nombre}</label>
                <p className='popup_eye-p'>{nombre_castellano}</p>
                <label className='popup_eye-label'>Número de Código</label>
                <p className='popup_eye-p'>{codigo}</p>
                {parametro !== undefined &&
                 <>
                  <label className='popup_eye-label'>Número de parámetro</label>
                  <p className='popup_eye-p'>{parametro}</p>
                 </>
                }
                <label className='popup_eye-label'>Número de orden</label>
                <p className='popup_eye-p'>{orden}</p>
                {persona !== undefined  &&
                 <>
                  <label className='popup_eye-label'>Persona</label>
                  <p className='popup_eye-p'>{persona}</p>
                 </>
                }
                {rol !== undefined &&
                 <>
                  <label className='popup_eye-label'>Rol de la usuario</label>
                  <p className='popup_eye-p'>{rol}</p>
                 </>
                }
                {sexo !== undefined &&
                 <>
                  <label className='popup_eye-label'>Sexo de la persona</label>
                  <p className='popup_eye-p'>{sexo}</p>
                 </>
                }
                <label className='popup_eye-label'>Estado</label>
                <p className='popup_eye-p'>{estado_nombre_castellano}</p>
              </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}