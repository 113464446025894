import { Edit } from '@mui/icons-material';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import { Box } from '@mui/system';
import { useFormik } from 'formik';
import React, { useState } from 'react';
import eventAdapter from '../../adapters/events';
import { putData } from "../../api/subscribers-api";
import "../../styles/components/PopUpAdd.css";
import { popupBoxContainer } from '../../styles/components/PopUpBoxcontainer';
import CustomLoadingButton from '../Buttons/CustomLoadingButton';

export default function PopUpEditEvent({ item, estados, codigo, handleUpdated }) {
  const [error, setError] = useState("");
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const formik = useFormik({
    initialValues: eventAdapter(item),
    onSubmit: async values => {
      setLoading(true)
      const response = await putData(`200/evento/${codigo}`, 'PUT', JSON.stringify(values));
      if (response.code === 201 || response.code === 200) {
        setError("");
        setOpen(false)
        handleUpdated('actualizado');
      } else {
        setError("Los campos son incorrectos");
      }
      setLoading(false)
    },
  });

  return (
    <>
      <Button onClick={handleOpen}><Edit sx={{ color: 'white' }}/></Button>
      <Modal
        keepMounted
        open={open}
        onClose={handleClose}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box sx={popupBoxContainer}>
          <h2 className='popup_add-h2'>Editar Evento</h2>
          <form className='popup_edit-form' onSubmit={formik.handleSubmit}>
            <div className='popup_add-input-container'>
              <label className='popup_add-label'>Nombre del evento</label>
              <input
                type='text'
                className='popup_add-input'
                placeholder="Evento"
                name='evento_nombre'
                value={formik.values.evento_nombre}
                onChange={formik.handleChange}
              />
            </div>
            <div className='popup_add-input-container'>
              <label className='popup_add-label'>Número de orden</label>
              <input
                type='number'
                className='popup_add-input'
                placeholder="Orden"
                name='evento_orden'
                value={formik.values.evento_orden}
                onChange={formik.handleChange}
              />
            </div>
            <div className='popup_add-input-container'>
              <label className='popup_add-label'>Fecha del evento</label>
              <input
                type='date'
                className='popup_add-input'
                placeholder="Fecha"
                name='evento_fecha'
                value={formik.values.evento_fecha ? formik.values.evento_fecha : undefined}
                onChange={formik.handleChange}
              />
            </div>
            <div className='popup_add-input-container'>
              <label className='popup_add-label'>Comentario</label>
              <input
                type='text'
                className='popup_add-input'
                placeholder="Observación"
                name='evento_observacion'
                value={formik.values.evento_observacion ? formik.values.evento_observacion : undefined}
                onChange={formik.handleChange}
              />
            </div>
            <div  className='popup_edit-p'>
              <label className='popup_edit-label'>Estado</label>
              <select className='popup_edit-select' name="tipo_estado_parametro" placeholder="Estado" onChange={formik.handleChange}>
                <option className='popup_edit-option' disabled defaultValue='default' >Selecciona un estado</option>
                {estados.map((item, index)=>
                  <option
                    className='popup_edit-option'
                    key={index}
                    value={item.tipo_parametro}
                  >
                    {item.tipo_nombre_castellano} {/* le viene del backend con este nombre de parametro: "tipo_parametro" pero se carga en el formulario con este nombre: "tipo_estado_parametro" */}
                  </option>
                )}
                </select>
              </div>
            <div className="error-message_popup">{error ? <p>{error}</p> : null}</div>
            <CustomLoadingButton
              loading={loading}
            >
              Actualizar
              </CustomLoadingButton>
          </form>
        </Box>
      </Modal>
    </>
  );
}