import DeleteIcon from '@mui/icons-material/Delete';
import Button from '@mui/material/Button';
import React, { useState } from 'react';
import { putData } from "../../api/subscribers-api";


export default function PopUpDeleteEvent({ codigo, handleUpdated }) {
  const [error, setError] = useState("No se pudo anular");
  const handleSubmit = async () => {
    const usuario_codigo = (localStorage.getItem('userData'));
    let body = JSON.stringify({
      auditoria_ip: '0.0.0.0',
      tipo_accion_codigo: 2,
      auditoria_usuario_codigo: usuario_codigo,
      tipo_estado_parametro: 2,
      evento_codigo: codigo
    })
    try {
      const response = await putData(`200/evento/${codigo}`, 'PUT', body);
      if (response.code === 201 || response.code === 200) {
        setError("");
        handleUpdated('eliminado');
      } else {
        setError(error);
      }
    }
    catch (err) {
      setError(err);
    }
  };

  return (
    <div>
      <Button onClick={handleSubmit}><DeleteIcon sx={{ color: 'white' }}/></Button>
    </div>
  );
}