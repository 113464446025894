import * as React from 'react';
import Paper from '@mui/material/Paper';
import "../styles/components/Charts.css";
import {
  Chart,
  BarSeries,
  Title,
  ArgumentAxis,
  ValueAxis,
} from '@devexpress/dx-react-chart-material-ui';

export default function ChartAsesor ({ asesores }){
  let Asesores = [];
  if (asesores){
    Asesores = asesores.filter(item => item.evento_asesor_codigo ? item.evento_asesor_codigo !== 14 && item.evento_asesor_codigo !== 15 && item.evento_asesor_codigo !== 1 : item)
  }

  return (
    <>
      {
        Asesores.length ?
        <Paper className='charts' >
          <Chart className='charts__asesores'
            data={Asesores}
            val={1}
          >
            <BarSeries
              valueField="evento_asesor_cantidad"
              argumentField="evento_asesor_nombre"
              color='#80BC00'
              barWidth={0.7}
            />
            <Title text='Asesores'/>
            <ArgumentAxis/>
            <ValueAxis/>
          </Chart>
        </Paper>
        :
        <h3>No hay datos registrados por los asesores en este rango de fechas, por favor elige otro rango</h3>
      }
    </>
  );
}
