
import React from 'react';
import MenuIcon from '@mui/icons-material/Menu';
import AccountMenu from './AccountMenu';
import "../styles/components/Header.css";


export default function Header(props){
  let toggle = false;
  const handleClick = () => {
    toggle = !toggle;
    props.changeStyle(toggle);
  }
  return (
    <header className="header">
      <button onClick={handleClick} className='header_burguer-btn'>
        <MenuIcon className='MuiIcon-root' />
      </button>
      <AccountMenu />
    </header>
  );
};