import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import { Box } from '@mui/system';
import { useFormik } from 'formik';
import React, { useState } from 'react';
import { postData } from "../../api/subscribers-api";
import "../../styles/components/PopUpAdd.css";
import { popupAddBoxContainer } from '../../styles/components/PopUpAddBoxcontainer';
import { initialvalues_pop_add } from '../../utils/initialvalues-pop-add';
import CustomLoadingButton from '../Buttons/CustomLoadingButton';

export default function PopUpAdd({ handleUpdated }) {
  const [error, setError] = useState("");
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const formik = useFormik({
    initialValues: initialvalues_pop_add,
    onSubmit: async values => {
      const data = {...values, tipo_nombre_ingles: values.tipo_nombre_castellano, tipo_nombre_portugues: values.tipo_nombre_castellano}
      const response = await postData("100/dominio", 'POST', JSON.stringify(data));
      if (response.code === 201 || response.code === 200) {
        setError("");
        setOpen(false);
        handleUpdated('creado');
      } else {
        setError(response.message_short);
      }
      setLoading(false);
    },
  });
  return (
    <div>
      <Button onClick={handleOpen}><AddIcon sx={{ color: 'darkslategray' }}/></Button>
      <Modal
        keepMounted
        open={open}
        onClose={handleClose}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box sx={popupAddBoxContainer}>
          <h2 className='popup_add-h2'>Agregar Carrera</h2>
          <form className='popup_edit-form' onSubmit={formik.handleSubmit}>
            <div className='popup_add-input-container'>
              <label className='popup_add-label'>Nombre de carrera</label>
              <input
                type='text'
                className='popup_add-input'
                placeholder="Carrera"
                name='tipo_nombre_castellano'
                onChange={formik.handleChange}
              />
            </div>
            <div className='popup_add-input-container'>
              <label className='popup_add-label'>Parámetro</label>
              <input
                type='number'
                className='popup_add-input'
                placeholder="Parametro"
                name='tipo_parametro'
                onChange={formik.handleChange}
              />
            </div>
            <div className="error-message_popup">{error ? <p>{error}</p> : null}</div>
            <CustomLoadingButton
              loading={loading}
            >
              Guardar
              </CustomLoadingButton>
          </form>
        </Box>
      </Modal>
    </div>
  );
}