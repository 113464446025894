import React from 'react'

function ExportCSVConverter(data) {
    return data.map((item) => ({
        "Evento": item.evento_nombre,
        "Tipo de Carrera": item.tipo_carrera_nombre_castellano,
        "Nombre ": item.evento_formulario_nombre,
        "Correo ": item.evento_formulario_email,
        "Telefono ": item.evento_formulario_telefono,
        "Fecha de Evento": item.evento_fecha,
        "Alta Usuario": item.persona_nombre + " " + item.persona_apellido,
        "Fecha de Alta": item.alta_fecha_hora,
        "Comentario": item.evento_formulario_observacion,
      }));
}

export default ExportCSVConverter;